import React from 'react';
import { Paragraph } from '../../../common/elements/paragraph/paragraph';

export const LastSaturdays: React.FC = () => {
    const getLastSaturday = (year: number, month: number): Date => {
        const lastDayOfMonth = new Date(year, month + 1, 0);
        const dayOfWeek = lastDayOfMonth.getDay();
        const diff = dayOfWeek >= 6 ? dayOfWeek - 6 : 1 + dayOfWeek; // Calculate difference to last Saturday
        return new Date(year, month, lastDayOfMonth.getDate() - diff);
    };

    const getAllLastSaturdays = () => {
        const lastSaturdays: { [year: string]: Date[] } = {};
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth();

        for (let year = currentYear; year <= currentYear + 1; year++) {
            // Loop through the current year and next year
            lastSaturdays[year] = [];

            // Start from current month if current year, otherwise from January
            const startMonth = year === currentYear ? currentMonth : 0;

            for (let month = startMonth; month < 12; month++) {
                const lastSaturday = getLastSaturday(year, month);

                // Include only future or current last Saturdays
                if (
                    lastSaturday >= today ||
                    (lastSaturday.getDate() === today.getDate() &&
                        lastSaturday.getMonth() === today.getMonth())
                ) {
                    lastSaturdays[year].push(lastSaturday);
                }
            }
        }

        return lastSaturdays;
    };

    const formatDate = (date: Date): string => {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];

        const month = months[date.getMonth()];
        const day = date.getDate();
        return `${month} ${day}`;
    };

    const allLastSaturdays = getAllLastSaturdays();

    return (
        <>
            {Object.keys(allLastSaturdays).map(
                year =>
                    allLastSaturdays[year].length > 0 && (
                        <Paragraph key={year}>
                            <strong>Upcoming dates {year}:</strong>
                            {allLastSaturdays[year].map((date, index) => (
                                <React.Fragment key={index}>
                                    <br />
                                    {formatDate(date)}
                                </React.Fragment>
                            ))}
                        </Paragraph>
                    ),
            )}
        </>
    );
};
