import React from 'react';
import { NavLink } from 'react-router-dom';
import { ExternalLink } from '../../elements/external-link/external-link';
import classNames from 'classnames';
import { isPortraitTablet } from '../../../app';
import { isMobileOnly } from 'react-device-detect';

import css from './navigation.module.scss';

import facebook from '../../assets/icon-facebook.png';
import instagram from '../../assets/icon-instagram.png';

export const Navigation = () => {
    const [checked, setChecked] = React.useState(true);

    const classes = classNames(
        isMobileOnly && css.navigationMobile,
        isPortraitTablet && css.navigationMobile,
        !isMobileOnly && !isPortraitTablet && css.navigationDesktop,
    );

    return (
        <nav className={css.root}>
            {(isMobileOnly || isPortraitTablet) && (
                <>
                    <input
                        checked={!checked}
                        className={css.checkbox}
                        id="toggle"
                        type="checkbox"
                        readOnly
                    />
                    <label
                        onClick={() => {
                            setChecked(old => !old);
                        }}
                        htmlFor="toggle"
                        className={css.hamburger}
                    />
                </>
            )}
            <ol className={`${classes} ${css.list}`}>
                <li className={css.item}>
                    <NavLink
                        exact={true}
                        activeClassName={css.active}
                        className={css.link}
                        to="/"
                        onClick={() => {
                            setChecked(old => !old);
                        }}
                    >
                        Home
                    </NavLink>
                </li>
                <li className={css.item}>
                    <NavLink
                        exact={true}
                        activeClassName={css.active}
                        className={css.link}
                        to="/entrance-prices"
                        onClick={() => {
                            setChecked(old => !old);
                        }}
                    >
                        Entrance prices
                    </NavLink>
                </li>
                <li className={css.item}>
                    <NavLink
                        exact={true}
                        activeClassName={css.active}
                        className={css.link}
                        to="/gallery"
                        onClick={() => {
                            setChecked(old => !old);
                        }}
                    >
                        Gallery
                    </NavLink>
                </li>
                <li className={css.item}>
                    <NavLink
                        activeClassName={css.active}
                        className={css.link}
                        to="/agenda"
                        onClick={() => {
                            setChecked(old => !old);
                        }}
                    >
                        Agenda
                    </NavLink>
                    <ol className={css.subNav}>
                        <li>
                            <NavLink
                                activeClassName={css.active}
                                className={css.link}
                                to="/agenda/mellow-monday"
                                onClick={() => {
                                    setChecked(old => !old);
                                }}
                            >
                                Mellow Monday
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName={css.active}
                                className={css.link}
                                to="/agenda/no-towel-tuesday"
                                onClick={() => {
                                    setChecked(old => !old);
                                }}
                            >
                                No Towel Tuesday
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName={css.active}
                                className={css.link}
                                to="/agenda/queer-night"
                                onClick={() => {
                                    setChecked(old => !old);
                                }}
                            >
                                Queer Night
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName={css.active}
                                className={css.link}
                                to="/agenda/the-night-is-young"
                                onClick={() => {
                                    setChecked(old => !old);
                                }}
                            >
                                The Night Is Young
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName={css.active}
                                className={css.link}
                                to="/agenda/bears-at-nz"
                                onClick={() => {
                                    setChecked(old => !old);
                                }}
                            >
                                Bears at NZ
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName={css.active}
                                className={css.link}
                                to="/agenda/tea-dance"
                                onClick={() => {
                                    setChecked(old => !old);
                                }}
                            >
                                Tea Dance
                            </NavLink>
                        </li>
                    </ol>
                </li>
                <li className={css.item}>
                    <NavLink
                        activeClassName={css.active}
                        className={css.link}
                        to="/massage"
                        onClick={() => {
                            setChecked(old => !old);
                        }}
                    >
                        Massage
                    </NavLink>
                    <ol className={css.subNav}>
                        <li>
                            <NavLink
                                activeClassName={css.active}
                                className={css.link}
                                to="/massage/richard"
                                onClick={() => {
                                    setChecked(old => !old);
                                }}
                            >
                                Richard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName={css.active}
                                className={css.link}
                                to="/massage/harald"
                                onClick={() => {
                                    setChecked(old => !old);
                                }}
                            >
                                Harald
                            </NavLink>
                        </li>
                    </ol>
                </li>
                <li className={css.item}>
                    <NavLink
                        activeClassName={css.active}
                        className={css.link}
                        to="/info"
                        onClick={() => {
                            setChecked(old => !old);
                        }}
                    >
                        Info
                    </NavLink>
                    <ol className={css.subNav}>
                        <li>
                            <NavLink
                                activeClassName={css.active}
                                className={css.link}
                                to="/info/our-house-rules"
                                onClick={() => {
                                    setChecked(old => !old);
                                }}
                            >
                                Our house rules
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName={css.active}
                                className={css.link}
                                to="/info/sexual-consent-policy"
                                onClick={() => {
                                    setChecked(old => !old);
                                }}
                            >
                                Sexual Consent Policy
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName={css.active}
                                className={css.link}
                                to="/info/sexual-health"
                                onClick={() => {
                                    setChecked(old => !old);
                                }}
                            >
                                Sexual Health
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName={css.active}
                                className={css.link}
                                to="/info/our-transgender-policy"
                                onClick={() => {
                                    setChecked(old => !old);
                                }}
                            >
                                our transgender policy
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName={css.active}
                                className={css.link}
                                to="/info/nz-supports"
                                onClick={() => {
                                    setChecked(old => !old);
                                }}
                            >
                                nz-supports
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName={css.active}
                                className={css.link}
                                to="/info/links"
                                onClick={() => {
                                    setChecked(old => !old);
                                }}
                            >
                                Gay businesses & groups
                            </NavLink>
                        </li>
                    </ol>
                </li>
            </ol>
            <div className={css.social}>
                <ExternalLink
                    href="https://www.instagram.com/sauna_nieuwezijds/"
                    text="instagram"
                    icon={instagram}
                    className={css.socialLink}
                />
                <ExternalLink
                    href="https://www.facebook.com/SaunaNZAmsterdam"
                    text="facebook"
                    icon={facebook}
                    className={css.socialLink}
                />
            </div>
        </nav>
    );
};
