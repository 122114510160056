import React from 'react';
import { Paragraph } from '../../../common/elements/paragraph/paragraph';

export const SecondSundays: React.FC = () => {
    const getSecondSunday = (year: number, month: number): Date => {
        // Get the first day of the month
        const firstDayOfMonth = new Date(year, month, 1);
        const dayOfWeek = firstDayOfMonth.getDay();

        // Calculate the day difference to the first Sunday
        const diffToSunday = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;

        // The second Sunday is 7 days after the first Sunday
        return new Date(year, month, 1 + diffToSunday + 7);
    };

    const getAllSecondSundays = () => {
        const secondSundays: { [year: string]: Date[] } = {};
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth();

        for (let year = currentYear; year <= currentYear + 1; year++) {
            // Loop through the current year and next year
            secondSundays[year] = [];

            // Start from current month if current year, otherwise from January
            const startMonth = year === currentYear ? currentMonth : 0;

            for (let month = startMonth; month < 12; month++) {
                const secondSunday = getSecondSunday(year, month);

                // Include only future or current second Sundays
                if (
                    secondSunday >= today ||
                    (secondSunday.getDate() === today.getDate() &&
                        secondSunday.getMonth() === today.getMonth())
                ) {
                    secondSundays[year].push(secondSunday);
                }
            }
        }

        return secondSundays;
    };

    const formatDate = (date: Date): string => {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];

        const month = months[date.getMonth()];
        const day = date.getDate();
        return `${month} ${day}`;
    };

    const allSecondSundays = getAllSecondSundays();

    return (
        <>
            {Object.keys(allSecondSundays).map(
                year =>
                    allSecondSundays[year].length > 0 && (
                        <Paragraph key={year}>
                            <strong>Upcoming dates {year}:</strong>
                            {allSecondSundays[year].map((date, index) => (
                                <React.Fragment key={index}>
                                    <br />
                                    {formatDate(date)}
                                </React.Fragment>
                            ))}
                        </Paragraph>
                    ),
            )}
        </>
    );
};
