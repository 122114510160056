import React from 'react';
import { Title } from '../../elements/title/title';

import css from './entrance-prices-table.module.scss';

export const EntrancePricesTable = () => {
    return (
        <>
            <Title as="h2" text="entrance prices" />
            <table className={css.root}>
                <tr>
                    <td>standard entry</td>
                    <td>€ 26 (including first drink)</td>
                </tr>
                <tr>
                    <td>monday till thursday</td>
                    <td>entry after midnight €10</td>
                </tr>
                <tr>
                    <td>26 or younger</td>
                    <td>
                        € 18 (including first drink)
                        <br />€ 12,50 monday & thursday (including first drink)
                    </td>
                </tr>
                <tr>
                    <td>10-visit-card</td>
                    <td>€ 225 (including first drink on every visit)</td>
                </tr>
                <tr>
                    <td>re-entry card (same day)</td>
                    <td>€ 5</td>
                </tr>
                <tr>
                    <td>extra towel</td>
                    <td>€ 1</td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        we also sell slippers (€ 4,50) and offer douche
                        facilities.
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        douche hoses (spoelslangen) available at the bar (€ 3)
                    </td>
                </tr>
            </table>
        </>
    );
};
