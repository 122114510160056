import React from 'react';
import { Helmet } from 'react-helmet';
import { isMobileOnly } from 'react-device-detect';
import { isPortraitTablet } from '../../../app';
import { BackLink } from '../../../common/elements/back-link/back-link';
import { PictureBlock } from '../../../common/elements/picture-block/picture-block';
import { ContentBlock } from '../../../common/elements/content-block/content-block';
import { Title } from '../../../common/elements/title/title';
import { ScrollToTop } from '../../../common/scripts/scroll-to-top';
import { Paragraph } from '../../../common/elements/paragraph/paragraph';

import css from '../agenda-item.module.scss';

import teaDance from '../../../common/assets/tea-dance.jpg';
import { SecondSundays } from './second-sundays';

export const TeaDance = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>
                    Tea Dance | Agenda | Nieuwezijds Gay Sauna Amsterdam
                </title>
                <meta
                    name="description"
                    content="Information about Tea Dance, one of the events in the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <PictureBlock imageSrc={teaDance} />
                <ContentBlock position="2">
                    {(isMobileOnly || isPortraitTablet) && (
                        <BackLink href="/agenda" text="agenda" />
                    )}
                    <Title as="h1" text="tea dance" noMargin />
                    <Title
                        as="h2"
                        text="dj's, darjeeling & U baby!"
                        underlined={false}
                    />
                    <Paragraph>
                        every second sunday from 15:00 till 21:00
                    </Paragraph>

                    <Paragraph>
                        Expect great DJs, bubbly drinks, and plenty of hotties!
                    </Paragraph>

                    <Paragraph>Dresscode: swimwear towel or naked</Paragraph>

                    <Paragraph>Don’t miss it! 🎶🥂</Paragraph>

                    <SecondSundays />
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
