import React from 'react';
import { Helmet } from 'react-helmet';
import { isMobileOnly } from 'react-device-detect';
import { isPortraitTablet } from '../../../app';
import { BackLink } from '../../../common/elements/back-link/back-link';
import { PictureBlock } from '../../../common/elements/picture-block/picture-block';
import { ContentBlock } from '../../../common/elements/content-block/content-block';
import { Title } from '../../../common/elements/title/title';
import { ScrollToTop } from '../../../common/scripts/scroll-to-top';
import { Paragraph } from '../../../common/elements/paragraph/paragraph';

import css from '../agenda-item.module.scss';

import theNightIsYoung from '../../../common/assets/the-night-is-young.jpg';

export const TheNightIsYoung = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>
                    The Night Is Young | Agenda | Nieuwezijds Gay Sauna
                    Amsterdam
                </title>
                <meta
                    name="description"
                    content="Information about The Night is Young, one of the events in the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <PictureBlock imageSrc={theNightIsYoung} />
                <ContentBlock position="2">
                    {(isMobileOnly || isPortraitTablet) && (
                        <BackLink href="/agenda" text="agenda" />
                    )}
                    <Title as="h1" text="The Night Is Young" noMargin />
                    <Title
                        as="h2"
                        text="Every Thursday, all day"
                        underlined={false}
                    />

                    <Paragraph>
                        all guys 26 & younger, just € 12,50 entrance.
                        <br />
                        you can show off your sexy swimwear as speedos and other
                        swim briefs are allowed!
                    </Paragraph>

                    <Paragraph>
                        complimentary aufgüss (infusion) ceremonies at 19:00
                        (7pm) & 21:00 (9pm).
                        <br />
                        and a sexy body scrub at 20:00 (8pm).
                    </Paragraph>

                    <Paragraph>
                        hope to see you there, whatever your age! 🌈
                    </Paragraph>
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
